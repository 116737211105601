.li{
    color: white;
    font-size: 5rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style-type: none; 
    margin-top: 8%;
    margin-left: 0%;
}
.phoneli{
    color: white;
    font-size: 2rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style-type: none; 
    margin-top: 6rem;
}
.typeWriterText{
    color: white;
    font-size: 3rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style-type: none;   
}
@media screen and (max-width: 500px) {
    .typeWriterText{
      font-size: 30px;  
      margin-top: 2rem;
    }
}

.button{
    color: white;
    display: inline-flex;
    height: fit-content;
    width: fit-content;
    padding: 10px 5px;
    font-size: 18px;
    border-radius: 8px;
    background-color: black;
    border:  #0DF1BA solid;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 7rem;
    font-weight: 1000;
}
@media screen and (max-width: 500px) {
    .button{
        font-size: 14px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 4rem;
        font-weight: 1000;    
    }
}
@media screen and (max-width: 300px) {
    .button{
        font-size: 10px;
        margin-top: 2rem;
    }
}
.button:hover{
transform: scale(1.1);
transition: ease-in-out .2s;
text-decoration: none;
color: #0DF1BA;
}
.backgroundImg{
    background-image: linear-gradient(to right,#000000, #202020);
    min-height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.nameText{
    font-family: 'Fredoka One', cursive;
font-family: 'Montserrat', sans-serif;
}

