::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track{
  background-color: black;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb{
  background-color: #0DF1BA;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
  background-color: #079574;
}
::-webkit-scrollbar-corner{
  background-color: black;
}
/*  scrollarrow */
.scrollArrow {
  position: fixed; 
    width: 3rem;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    color: #0DF1BA; 
    bottom: 20px;
    left: 20px;
  }
  .scrollArrow:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  } 
  /*          footer */
  .footerCustom{
    background-color: transparent;
    width: 100%;
    padding: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: auto;
  }
  .footerCustomP{
    color:#0DF1BA;
    align-items: center;
    margin: 0;
    padding: 5px;
  }
  .footerCustomDiv1{
    display: flex;
    width: 55%;
    justify-content: end;
  }
  @media screen and (max-width: 440px) {
    .footerCustomDiv1{
      justify-content: center;
      width: 100%;
    }
  }
  .footerCustomDiv2{
    display: flex;
    width: 45%;
    justify-content: end;
  }
  @media screen and (max-width: 440px) {
    .footerCustomDiv2{
      display: none;
    }
  }
  
  .footerCustom h4{
    font-family: 'Monoton', cursive;
    color:#0DF1BA;
    align-items: center;
    margin: 0;
    padding: 5px;
    font-size: 20px;
  }
  @media screen and (max-width: 645px) {
    .footerCustom h4{
      font-size: 15px;
    }
  }

  .tagStyling{
    font-size: 20px;
    color: #0DF1BA; 
    font-style: white;
    display: flex;
    justify-content: center;
    font-family: 'Monoton', cursive;
  }
  /* Navbar css ***********/
  .navLinkStyle{
    display: flex;
    padding: 5px;
    justify-content: center;
  }
  .phoneLinkStyle{
      transition: 0.2s all ease-out;
      display: flex;
      font-size: 18px;
      justify-content: center;
      padding: 10px;
  }
  
  /* About */
  .intro{
    display: flex;
    justify-content: center;
  }
  .userPh{
    display: flex; 
    border-radius: 100%;
    width: fit-content;
    height:fit-content;
  }
  .aboutMe{
    padding:1rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;    
    color: white;
    font-size: 25px;
    }
    @media screen and (max-width: 450px) {
      .aboutMe{
        padding: 5px;
        font-size: 15px;
      }
    }
    /******** Skills section */
    .skillsContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      padding: 10px;
      margin-top: 3rem;
      flex-wrap: wrap;
    }
    .skill{
      border: #0DF1BA solid 2px;
      border-radius: 20px;
      font-size: 17px;
      font-weight: 1000;
      background-color: transparent;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 5px;
      color: white;
      text-align: center;
      width: fit-content;
      height: fit-content;
      display:flex;
    }
    @media screen and (max-width: 520px) {
      .skill{
        font-size: 12px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 2px;
      }
    }
    /*career section************/
    .careerBox{
      padding: 10px;
      display: flex;
      align-items: center;
      margin-top: 3rem;
      flex-wrap: wrap;
      width: 80%;
      justify-content: center;
    }
    .careerBoxHeader{
      display: flex;
      align-items: center;
      color: #0DF1BA;
      font-weight: 600;
      width: 50%;
      margin-left:  1rem;
      font-family: 'Monoton', cursive;
      padding: 10px;
      margin-bottom: 1rem;
    }
    .careerBoxHeader::after{
        content: '';
        flex: 1;
        margin-left: 1rem;
        height: 2px;
        background-color: white;
    }
    .roleColumn{
      color: white;
      font-size: 17px;
      font-weight: 1000;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
    }
    .roleDesc{
      padding: 10px 5px;
      margin: 0;
      color: white;
     }
     .roleDiv{
       text-align: start;  
     }
     .roleDiv p{
       color: #0DF1BA;
       font-weight: 500;
     }
     .roleDiv ul{
       list-style-type: none;
       font-weight: 500;
     }
     .roleDiv ul li::before{
       content: "\2023";
       font-weight: bold;
       font-size: 1.5em;
       color: #0DF1BA;
       display: inline-block;
       width: 1em;
       margin-left: -1em;
     }

    .nav.nav-pills .nav-link.active {
      background-color: transparent;
      border-bottom: #0DF1BA solid 2px;
      transition: ease-in-out .25s;
      border-radius: 0%;    
  }
  .nav .nav-link:hover{
      transform: scale(1.1);
      transition: ease-in-out .2s;
      border-bottom: white solid 2px;
      border-radius: 0%;
    }

    /******** Contact page***/
.connectDiv{
  width: 60%;
  display: flex; 
  justify-content: center; 
  flex-direction: column;
   text-align: center;
    margin-top: 4rem; 
  padding: 10px;
}
@media screen and (max-width: 400px) {
  .connectDiv{
    margin-top: 2rem;
    width: 80%
  }
}
.textDiv{
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 1rem;
}
@media screen and (max-width: 400px) {
  .textDiv{
    font-size: 14px;
  }
}
.connectIcons{
  height: 5rem;
  padding: 10px 5px;
  margin: 2rem .5rem;
  color:#0DF1BA;
}
.connectIcons:hover{
  color:#079574;
  transform: scale(1.1);
}
@media screen and (max-width: 400px) {
  .connectIcons{
    height: 3rem;
    margin: 2rem .2rem;
    padding: 5px;
  }
}
/******************* Portfolio*******/
.cardBox{
  width: 100%;
  height:fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.card{
  width:fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  padding: 5px 5px;
  margin: 10px 10px;
  border: #0DF1BA solid 2px;
  justify-content: center;
  background-color: rgb(21, 20, 20);
}
.card:hover{
transform: scale(1.05);
transition: ease-in-out .2s;
opacity: .5;
}
.cardHeader{
  width: 16rem;
  font-size: 15px;
  font-weight: 800;
  padding: 5px 5px;
  flex-wrap: wrap;
  color: white;
  margin-bottom: 0px;
}


 
